import styled from 'styled-components';

export default styled.div`
  margin-bottom: 48px;
  .page-title-container {
    margin: 24px 0;
  }
  .blog-content-wrapper {
    padding: var(--pm-24-16);
    background: #fff;
    border-radius: 4px;
    .blog-content-title {
      color: #000;
      line-height: 45px;
    }
  }
  .blog-info {
    color: #646566;
    display: flex;
    align-items: center;
    margin-top: 16px;
    img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .blog-content {
    margin-top: 32px;
    min-height: 492px;
    word-break: break-word;
  }
  .blog-info-time {
    margin-left: 24px;
    display: inline-flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
  .category-container {
    margin-top: 28px;
    color: #646566;
    span {
      color: #969799;
      background: #ebedf0;
      padding: 2px 4px;
      margin-left: 8px;
    }
  }
  .user-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    .blog-reply-container {
      /* margin-right: 320px; */
    }
    .empty-container {
      width: 248px;
    }
  }
  .blog-statistics-container {
    margin-top: 32px;
  }
  .blog-comment-container {
    margin-top: var(--pm-24-16);
    border-top: 1px solid #ebedf0;
  }

  @media screen and (max-width: 750px) {
    .page-title-container {
      padding: 0 16px;
    }
    .blog-content {
      min-height: auto;
    }
    .user-action-container {
      flex-direction: column;
      margin-top: 16px;
      .blog-share-container {
        margin-top: 26px;
      }
    }
  }
`;
