import styled from 'styled-components';

export default styled.div`
  .blog-action-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    svg {
      margin-right: 8px;
    }
  }
  .blog-action-container-fans {
    svg {
      fill: var(--color_button);
    }
  }
  .reply-btn {
    height: 36px;
    min-width: 82px;
  }
`;
