import styled from 'styled-components';

export default styled.div`
  display: flex;
  .share-svg-container {
    .icon-item-share {
      fill: var(--color_button);
    }
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;
