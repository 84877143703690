import Style from './style';
import PageTitle from '../../../components/base/page-title';
import { withRouter } from 'react-router-dom';
import shopApi from 'api/shop';
import { formateTimeByTimeZone } from 'utils/datetime';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Text } from 'components/base';
import BlogReply from './components/blog-reply';
import BlogShare from './components/blog-share';
import BlogComment from './components/blog-comment';
import BlogStatistics from './components/blog-statistics';
import { useRootContext } from 'client/pages/context/root';
import { getQueryString } from 'utils';
import goodsApi from 'api/goods';

const BlogDetail = (props) => {
  const { initialData = {} } = props;
  const { customerId } = initialData?.userInfo;
  const { kdtId } = initialData;
  const { categoryName, createdAt, richContent, title, creatorNo, avatar } = initialData?.pageData || {};
  const intl = useIntl();
  const { pathname } = props.location;
  const routerItemList = pathname.split('/');
  const id = Number(routerItemList[routerItemList.length - 1]);
  const [blog, setBlog] = useState({ richContent, title, createdAt, creatorNo, categoryName, avatar });
  const [blogStatistic, setBlogStatistic] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshBlog, setRefreshBlog] = useState(0);
  const [refreshCommentFlag, setRefreshCommentFlag] = useState(0); // 刷新评论
  const [refreshStatistics, setRefreshStatistics] = useState(0); // 刷新统计数据
  const [shopLogoUrl, setShopLogoUrl] = useState('');
  const { setLoginDialogInfo } = useRootContext();

  const getBlogDetail = () => {
    shopApi.getBlogById({ blogId: id, userId: customerId }).then((data) => {
      setBlog(data);
    });
  };

  // 刷新博客评论
  const onCommentRefresh = () => {
    setRefreshCommentFlag(refreshCommentFlag + 1);
  };

  // 刷新统计数据
  const onStatisticsRefresh = () => {
    setRefreshStatistics(refreshStatistics + 1);
  };

  // 获取博客统计数据
  const getBlogStatistic = () => {
    shopApi.getBlogStatistic({ kdtId, blogId: id }).then((data) => {
      setBlogStatistic(data);
    });
  };

  const onCheckIsLogin = () => {
    if (!customerId) {
      setLoginDialogInfo({
        visible: true,
      });
    }
    return !!customerId;
  };

  // 获取店铺logo
  useEffect(() => {
    shopApi.getShopLogo().then((res) => {
      const { shopLogoUrl } = res;
      setShopLogoUrl(shopLogoUrl);
    });
  }, []);

  useEffect(() => {
    getBlogDetail();
  }, [refreshBlog]);

  useEffect(() => {
    getBlogStatistic();
  }, [refreshStatistics]);

  const getMediaLinkById = async (mediaIds) => {
    const videos = await goodsApi.getVideosByIds({ mediaIds });
    return videos.map(({ playUrl }) => playUrl);
  };

  const dealVideoSource = async () => {
    try {
      const richText = document.querySelector('#goods-detail-text');
      const videos = richText?.querySelectorAll('video');
      // .video-with-id
      const mediaIds = Array.from(videos).map((item) => {
        const { mediaId } = getQueryString(item.children[0].src) || {};
        return mediaId;
      });

      const videosUrls = mediaIds.length ? await getMediaLinkById(mediaIds) : [];

      Array.from(videos).forEach((item, index) => {
        item.children[0].src = videosUrls[index];
        // item.className = item.className.replace('video-with-id', '');
        item.load();
      });
    } catch (err) {
      console.log(err, '替换视频资源异常');
    }
  };

  useEffect(() => {
    dealVideoSource();
  }, [blog?.richContent]);

  return (
    <Style>
      <div className='page-title-container'>
        <PageTitle
          list={[
            { text: intl.formatMessage({ defaultMessage: '首页', id: 'store.edit.homepage' }), href: '/' },
            { text: intl.formatMessage({ defaultMessage: '博客', id: 'blog.app.name' }), href: '/blogs' },
            { text: intl.formatMessage({ defaultMessage: '详情', id: 'common_detail' }) },
          ]}
        />
      </div>
      <div className='blog-content-wrapper'>
        <div className='blog-content-title'>
          <Text size={5}>{blog.title}</Text>
        </div>
        <div className='blog-info'>
          {blog?.avatar && <img src={blog?.avatar} />}
          <span>
            {blog.creatorNo ||
              intl.formatMessage({
                defaultMessage: '官方客服',
                id: '81058b291f23454592eff88f99e0a7e8',
              })}
          </span>
          <span className='blog-info-time'>
            <svg width={16} height={16} fill='#646566'>
              <use xlinkHref='#iconicon_loading1' />
            </svg>
            {formateTimeByTimeZone(new Date(blog.createdAt))}
          </span>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: blog.richContent }} id='goods-detail-text' className='blog-content' />
        </div>
        {blog?.categoryName && (
          <div className='category-container'>
            {intl.formatMessage({ defaultMessage: '分类', id: 'd0771a42bbc49a6941f59913fcda35e3' })}:{' '}
            <span>{blog.categoryName}</span>
          </div>
        )}
        <div className='user-action-container'>
          <div className='empty-container' />
          <BlogReply
            onCheckIsLogin={onCheckIsLogin}
            blogId={id}
            customerId={customerId}
            onStatisticsRefresh={onStatisticsRefresh}
            onCommentRefresh={onCommentRefresh}
            blogStatistic={blogStatistic}
            userRelateInfo={blog?.userRelateInfo}
          />
          <div className='blog-share-container'>
            <BlogShare initialData={initialData} blogId={id} onStatisticsRefresh={onStatisticsRefresh} />
          </div>
        </div>

        <div className='blog-statistics-container'>
          <BlogStatistics blogStatistic={blogStatistic} />
        </div>

        <div className='blog-comment-container'>
          <BlogComment
            blogId={id}
            customerId={customerId}
            kdtId={kdtId}
            onCommentRefresh={onCommentRefresh}
            refreshCommentFlag={refreshCommentFlag}
            onStatisticsRefresh={onStatisticsRefresh}
            onCheckIsLogin={onCheckIsLogin}
            shopLogoUrl={shopLogoUrl}
          />
        </div>
      </div>
    </Style>
  );
};

export default withRouter(BlogDetail);
