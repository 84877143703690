import Style from './style';
import React, { useState, useEffect } from 'react';
import BlogCommentItem from '../blog-comment-item';
import shopApi from 'api/shop';

const BlogComment = (props) => {
  const {
    customerId,
    blogId,
    kdtId,
    onCommentRefresh,
    refreshCommentFlag,
    onStatisticsRefresh,
    onCheckIsLogin,
    shopLogoUrl,
  } = props;
  const [commentList, setCommentList] = useState([]);

  // 获取评论列表
  useEffect(() => {
    const params = {
      blogId,
      kdtId,
      userId: customerId,
    };
    shopApi.getBlogCmts(params).then((res) => {
      setCommentList(res);
    });
  }, [refreshCommentFlag]);

  return (
    <Style>
      <div>
        {commentList.map((item) => {
          return (
            <BlogCommentItem
              {...item}
              key={item?.commentId}
              blogId={blogId}
              onCommentRefresh={onCommentRefresh}
              onStatisticsRefresh={onStatisticsRefresh}
              shopLogoUrl={shopLogoUrl}
              onCheckIsLogin={onCheckIsLogin}
            />
          );
        })}
      </div>
    </Style>
  );
};

export default BlogComment;
