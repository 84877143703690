import styled from 'styled-components';

export default styled.div`
  width: 512px;
  max-width: 100%;
  .reply-edit-container {
    margin-top: 12px;
  }
  .commit-btn-container {
    margin-top: 24px;
    .commit-btn {
      width: 100%;
    }
  }
  .zent-textarea {
    height: auto !important;
  }
`;
