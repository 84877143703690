import React, { useState, useEffect } from 'react';
import Style from './style';
import { Button, Dialog, Notify } from 'zent';
import onBlogReplyEditDialog from '../blog-reply-edit';
import { useIntl } from 'react-intl';
import shopApi from 'api/shop';

const { closeDialog } = Dialog;
const BlogReply = ({
  onCheckIsLogin,
  onStatisticsRefresh,
  blogStatistic,
  blogId,
  onCommentRefresh,
  customerId,
  userRelateInfo,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [isFans, setIsFans] = useState(false);

  //初始化点赞状态状态
  useEffect(() => {
    setIsFans(Boolean(userRelateInfo?.isFans));
  }, [userRelateInfo]);

  // 点赞或者取消点赞
  const onTogglePraiseBlog = () => {
    if (loading || !onCheckIsLogin()) return;
    const apiName = isFans ? 'cancelFansBlog' : 'fansBlog';
    const toastTips = isFans
      ? intl.formatMessage({ defaultMessage: '取消成功', id: '36ffbcb3d53f43c99a1e78bb1ff80c08' })
      : intl.formatMessage({ defaultMessage: '点赞成功', id: 'e0f458ba364f47ffb15569f6115d4ec7' });
    setLoading(true);
    shopApi[apiName]({ blogId, userId: customerId }).then(() => {
      Notify.success(toastTips);
      setIsFans(!isFans);
      onStatisticsRefresh();
      setLoading(false);
    });
  };

  //确认评论
  const onSubmitComment = (content) => {
    const params = {
      blogId,
      content,
    };
    shopApi.publishBlogCmt(params).then(() => {
      Notify.success(intl.formatMessage({ defaultMessage: '评论成功', id: '349356ce58d641b18984511f6a1c3f75' }));
      closeDialog('blog-reply-dialog');
      onCommentRefresh();
      onStatisticsRefresh();
    });
  };

  //打开评论博客弹窗
  const onOpenCommentDialog = () => {
    if (!onCheckIsLogin()) return;
    onBlogReplyEditDialog(
      intl.formatMessage({ defaultMessage: '评论', id: 'product_comments' }),
      intl,
      onSubmitComment,
    );
  };

  return (
    <Style>
      <Button onClick={onTogglePraiseBlog} disabled={loading} className='reply-btn'>
        <div className='blog-action-container blog-action-container-fans'>
          <svg width={15} height={15}>
            <use xlinkHref={isFans ? '#iconic-dianzan2' : '#iconic-dianzan1'}></use>
          </svg>
          <span>{blogStatistic?.fansNum}</span>
        </div>
      </Button>

      <Button type='primary' onClick={onOpenCommentDialog} className='reply-btn'>
        <div className='blog-action-container'>
          <svg width={15} height={15} fill='#fff'>
            <use xlinkHref='#iconic-xiugai'></use>
          </svg>
          <span>{intl.formatMessage({ defaultMessage: '评论', id: 'product_comments' })}</span>
        </div>
      </Button>
    </Style>
  );
};

export default BlogReply;
