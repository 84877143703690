import styled from 'styled-components';

export default styled.div`
    .main-container {
        display: flex;
        align-items: flex-start;
        padding-bottom: 24px;
        border-bottom: 1px solid #ebedf0;
        margin-top: 24px;
    }
    .head-img-container {
        margin-right: 16px;
        flex-shrink: 0;
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .content-container {
        flex-grow: 1;
        .comment-action-container {
            display: flex;
            justify-content: space-between;
            .back-container {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 8px;
                }
            }
            .user-name-container {
                font-weight: 500;
                color: #323233;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .name {
                    margin-right: 8px;
                }
                .admin-symbol {
                    color: ${(props) => props.theme.colorButton};
                    padding: 2px 4px;
                    background: ${(props) => {
                      if (!props?.theme?.colorButtons) return;
                      return props?.theme?.colorButtons[8];
                    }};
                }
            }
            .create-time-container {
                margin-top: 2px;
                line-height: 17px;
                font-size: 12px;
                color: #646566;
            }
        }
        .comment-content-container {
            margin-top: 12px;
            line-height: 20px;
            color: #323233;
            word-break: break-word;
            .delete-text {
                color: #969799;
            }
        }

        .two-level-comment-container {
            margin-top: 18px;
            background-color: #f7f8fa;
            border-radius: 2px;
            .two-level-comment {
                &:not(:first-child) {
                    border-top: 1px solid #ebedf0;
                }

                padding: 16px 0;
                margin: 0 16px;
                display: flex;
                .tow-level-img-container {
                    flex-shrink: 0;
                    margin-right: 16px;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }
                .two-level-comment-content {
                    flex: 1;
                    .two-level-content {
                        margin-top: 16px;
                        line-height: 20px;
                        color: #323233;
                        word-break: break-word;
                        .delete-text {
                            color: #969799;
                        }
                    }
                    .user-name-container {
                        
                        display: flex;
                        justify-content: space-between;
                        height: 32px;
                        align-items: center;
                        .name {
                            margin-right: 8px;
                        }
                       
                        .two-level-user-name {
                            font-weight: 500;
                            color: #323233;
                        }
                        .two-level-create-time {
                            color: #646566;
                            font-size: 12px;
                            line-height: 12px;
                            margin-top: 8px;
                        }
                        .drop-down-icon-container {
                            cursor: pointer;
                        }
                      
                 
                    .share-num-container {
                        margin-top: 16px;
                        color: #646566;
                        .share-num {
                            padding-left: 28px;
                        }
                    }
                    .admin-symbol {
                    color: ${(props) => props.theme.colorButton};
                    padding: 2px 4px;
                    background: ${(props) => {
                      if (!props?.theme?.colorButtons) return;
                      return props?.theme?.colorButtons[8];
                    }};
                    }
                }
            }
        }
    }
    .comment-data-container {
        margin-top: 16px;
        color: #646566;
        display: flex;
        .comment-data-item-first {
            margin-right: 24px;
        }
        .comment-data-item-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                color: #646566;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
`;
