import { Dialog, Input, Button, Notify } from 'zent';
import shopApi from 'api/shop';
import React, { useState, useEffect } from 'react';
import Style from './style';

const { openDialog, closeDialog } = Dialog;
const BlogReplyEdit = ({ intl, onSubmitComment }) => {
  const [replyContent, setReplyContent] = useState('');

  // 编辑评论
  const onEditReply = (e) => {
    const content = e.target.value;
    setReplyContent(content);
  };

  // 提交评论
  const onSubmit = () => {
    const content = replyContent.trim();
    if (!content) {
      Notify.error('评论内容不能为空');
      return;
    }
    onSubmitComment(content);
  };
  return (
    <Style>
      <div>{intl.formatMessage({ defaultMessage: '内容描述', id: 'ab74280c011f4dfba6de2a9a87da597a' })}</div>
      <div className='reply-edit-container'>
        <Input
          type='textarea'
          maxLength={500}
          autoSize
          rows={4}
          onChange={onEditReply}
          placeholder={intl.formatMessage({
            defaultMessage: '详细说明问题，以便更好的获得问答',
            id: '26e4da8ff7114b85bbcdbe2991bd98ad',
          })}
        ></Input>
      </div>
      <div className='commit-btn-container'>
        <Button type='primary' className='commit-btn' onClick={onSubmit}>
          {intl.formatMessage({ defaultMessage: '提交', id: 'general.submit' })}
        </Button>
      </div>
    </Style>
  );
};

const onBlogReplyEditDialog = (title, intl, onSubmitComment) => {
  openDialog({
    title,
    children: <BlogReplyEdit intl={intl} onSubmitComment={onSubmitComment}></BlogReplyEdit>,
    dialogId: 'blog-reply-dialog',
  });
};

export default onBlogReplyEditDialog;
