import styled from 'styled-components';

export default styled.div`
  .reply-data-container {
    display: flex;
    background-color: #f7f8fa;
    padding: 24px 24px 24px 40px;
    align-items: flex-start;
    .reply-data-line-one {
      margin-right: 100px;
      display: flex;
    }
    .reply-data-item {
      .reply-data-title {
        color: #969799;
        font-size: 12px;
        line-height: 17px;
      }
      .reply-data-value {
        margin-top: 8px;
        color: #323233;
        line-height: 20px;
        .reply-user-head {
          max-width: 20px;
          max-height: 20px;
          margin-right: 6px;
        }
      }
      .last-reply-user {
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
      .reply-top-container {
        margin-top: 8px;
        display: flex;
        .reply-top-item {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 16px;
          img {
            max-width: 32px;
            max-height: 32px;
          }
          .reply-num {
            position: absolute;
            top: 0px;
            right: -8px;
            padding: 2px 4px;
            max-width: 10px;
            font-size: 12px;
            background: rgba(0, 0, 0, 0.32);
            color: #fff;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      &:not(:last-child) {
        margin-right: 100px;
      }
    }
  }

  .blog-data-container {
    padding: 24px 24px 24px 40px;
    display: flex;
    background-color: #f7f8fa;
    margin-top: 4px;
    .blog-data-item-line-one {
      display: flex;
      margin-right: 100px;
    }
    .blog-data-item-line-two {
      display: flex;
      .empty-container {
        display: none;
        width: 50px;
      }
    }
    .blog-data-item {
      &:not(:last-child) {
        margin-right: 100px;
      }
      .blog-data-value {
        color: #323233;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
      .blog-data-title {
        font-size: 12px;
        line-height: 17px;
        color: #969799;
        margin-top: 8px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .reply-data-container {
      flex-direction: column;
      padding: 16px;
      .blog-data-item-line-one {
        margin-right: 0px;
        .reply-data-item {
          margin-right: 0px;
        }
        .reply-data-item-first {
          margin-right: 12%;
        }
      }
      .follow-container {
        margin-top: 16px;
      }
    }

    .blog-data-container {
      flex-direction: column;
      padding: 16px;
      .blog-data-item-line-one {
        justify-content: space-between;
        margin-right: 0;
      }
      .blog-data-item-line-two {
        justify-content: space-between;
        margin-top: 16px;
        .empty-container {
          display: block;
        }
      }

      .blog-data-item {
        margin-right: 0 !important;
      }
    }
  }
`;
