import Style from './style';
import shopApi from 'api/shop';
import React from 'react';
import { ShareListType } from 'const/share-type';
import { shareHandle } from 'utils/share-handle.js';

const BlogShare = ({ initialData = {}, blogId, onStatisticsRefresh }) => {
  const host = initialData?.host;
  const linkUrl = `https://${host}/blogs/detail/${blogId}`;

  const blogShareList = [
    {
      icon: 'iconline2',
      type: ShareListType.Line,
    },
    {
      icon: 'iconwhatsapp1',
      type: ShareListType.WhatsApp,
    },
    {
      icon: 'iconmessenger1',
      type: ShareListType.Messenger,
    },
    {
      icon: 'iconTwitter1',
      type: ShareListType.Twitter,
    },
    {
      icon: 'iconFB1',
      type: ShareListType.Facebook,
    },
  ];

  const onShareBlog = (type) => {
    console.log('linkUrl', linkUrl);
    shopApi
      .shareIncr({ blogId })
      .then(() => {
        onStatisticsRefresh();
      })
      .finally(() => {
        shareHandle({ shareType: type, linkUrl });
      });
  };
  return (
    <Style>
      {blogShareList.map((item) => {
        return (
          <div
            className='share-svg-container'
            key={item?.type}
            onClick={() => {
              onShareBlog(item.type);
            }}
          >
            <svg width={18} height={18} className='icon-item-share'>
              <use xlinkHref={`#${item.icon}`} />
            </svg>
          </div>
        );
      })}
    </Style>
  );
};

export default BlogShare;
